export default [
  {
    title: '主页',
    route: 'home',
    icon: 'HomeIcon',
  },

  {
    title: '评议系统',
    icon: 'AwardIcon',
    children: [
      {
        title: '评议列表',
        route: 'awards-lists',
      },

    ],
  },
]
